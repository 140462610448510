import { fetchChannelWebhooks } from '@/api/webhook'
import { useTableOptions } from '@/composables'
import { ref, watch } from '@vue/composition-api'
import { debounce } from 'lodash'

const cached = []

export default function useChannelWebhookList() {
  const webhookListTable = ref([])

  const tableColumns = [
    { text: 'ID', value: 'id' },
    { text: 'CHANNEL', value: 'channel_id' },
    { text: 'ENDPOINT', value: 'endpoint', sortable: false },
    { text: 'ACTIVE', value: 'active', sortable: false },
    { text: 'TYPES', value: 'types' },
    {
      text: 'ACTIONS',
      value: 'actions',
      align: 'center',
      sortable: false,
    },
  ]

  const options = ref(
    cached[0] || {
      sortBy: ['id'],
      sortDesc: [true],
    },
  )
  const searchQuery = ref(cached[1] || '')
  const totalListTable = ref(0)
  const loading = ref(false)
  const totalLocal = ref([])
  const selectedRows = ref([])

  // fetch data
  const loadChannelWebhooks = () =>
    fetchChannelWebhooks(
      useTableOptions(options.value, {
        // ...
      }),
    )
      .then(({ data }) => {
        const { records, pagination } = data.data

        webhookListTable.value = records
        totalListTable.value = pagination.total
        totalLocal.value = null

        // remove loading state
        loading.value = false
      })
      .catch(error => {
        console.log(error)
      })

  const cachable = [options, searchQuery]

  const cache = () => {
    cachable.forEach((variable, index) => {
      cached[index] = variable.value
    })
  }

  watch(
    cachable,
    debounce(() => {
      const newPage = options.value.page

      const hasDiff = cachable.find((variable, index) => index > 0 && cached[index] !== variable.value)

      if (newPage >= 2 && hasDiff) {
        options.value.page = 1
      }

      loading.value = true
      loadChannelWebhooks()
      cache()
    }, 300),
  )

  // *===============================================---*
  // *--------- UI ---------------------------------------*
  // *===============================================---*

  return {
    webhookListTable,
    tableColumns,
    searchQuery,
    totalListTable,
    loading,
    options,
    totalLocal,
    selectedRows,
    loadChannelWebhooks,
  }
}
