<template>
  <v-navigation-drawer
    :value="active"
    temporary
    touchless
    :right="!$vuetify.rtl"
    :width="$vuetify.breakpoint.smAndUp ? 680 : '100%'"
    class="pl-2 pr-2 pt-2 pb-2"
    app
    @input="(val) => $emit('aside', val)"
  >
    <div class="pl-4 pr-4 mt-4">
      <sync-webhook-types-form
        :cols="12"
        :selected-ids="resource ? resource.types.map(c => c.id) : []"
        :sync-base-path="`/admin/${path}/${resourceData.id}/types/sync`"
        :sync-able="syncAble"
        @change="syncAble = false; $emit('changed')"
      >
      </sync-webhook-types-form>

      <div class="mt-4 text-center">
        <v-btn
          color="primary"
          @click="setSyncable"
        >
          Sync
        </v-btn>
      </div>
    </div>
  </v-navigation-drawer>
</template>

<script>
import { mdiClose } from '@mdi/js'
import { ref } from '@vue/composition-api'
import SyncWebhookTypesForm from './SyncWebhookTypesForm.vue'

export default {
  components: { SyncWebhookTypesForm },

  model: {
    prop: 'active',
    event: 'aside',
  },

  props: {
    path: {
      type: String,
      default: 'distributor-webhooks',
    },
    active: {
      type: Boolean,
      required: true,
    },
    resource: {
      type: [Object, Boolean],
      default: false,
    },
  },

  setup(props) {
    const syncAble = ref(false)
    const loading = ref(false)
    const resourceData = ref(props.resource || false)

    const setSyncable = () => {
      syncAble.value = true
    }

    return {
      loading,

      resourceData,

      syncAble,
      setSyncable,

      icons: {
        mdiClose,
      },
    }
  },
}
</script>
